import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ApplicationState } from 'store/types';
import SecureLS from 'secure-ls';
const ls = new SecureLS({ encodingType: 'aes' });


function AuthGuard(props: any) {
  const { children } = props;
  let token: any;
  try {
    token = ls.get('token');
  } catch (error) {}
  if (!token) {
    return <Redirect to="/auth/login" />;
  }

  return children;
}

export default AuthGuard;
