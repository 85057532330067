import produce from 'immer';
import {ApplicationAction} from 'store/types';
import {CollectionState} from 'store/types';
//cli_import_section


const initialState: CollectionState = {

}


const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {
//cli_use_cases_section

    default: {
      return state;
    }
  }
};

export default reducer;