import React, {useState} from 'react';
import {ErrorHandler} from 'components';
import styles from './Application.module.css';
import NavBar from './components/NavBar';
import TopBar from './components/TopBar';


const Application = (props: any) => {
    const {children} = props;

    const [sidebarOpen, setSidebarOpen] = useState(false)

    return (
        <div className="min-h-full ">
            <ErrorHandler>
                <NavBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>
            </ErrorHandler>

            <div className="lg:pl-70 flex flex-col flex-1">
                <ErrorHandler>
                    <TopBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>
                </ErrorHandler>
                <main className="flex-1">
                    <ErrorHandler>{children}</ErrorHandler>
                </main>
            </div>
        </div>
    );
};

export default Application;
