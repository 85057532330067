import {GET_RECENT_USER, GetRecentUserAction, User} from "store/types";
import {
  FETCH_USER_REQUEST,
  FetchUserRequest,
  AddUserAction,
  ADD_USER_ACTION,
  UpdateUserSuccess,
  UPDATE_USER_SUCCESS,
  DeleteUserAction,
  DELETE_USER_ACTION
} from "store/types/UsersType";

export const fetchUserRequest = (users: User[], pages: number, total: number, page: number): FetchUserRequest => ({
  type: FETCH_USER_REQUEST,
  users: users,
  pages,
  total,
  page
})
export const addUserAction = (user: User): AddUserAction => ({
  type: ADD_USER_ACTION,
  user: user,
})
export const updateUserSuccess = (user: User): UpdateUserSuccess => ({
  type: UPDATE_USER_SUCCESS,
  user: user,
})
export const deleteUserAction = (public_id: string): DeleteUserAction => ({
  type: DELETE_USER_ACTION,
  public_id: public_id,
})

export const getRecentUserAction = (data: User[]): GetRecentUserAction => ({
  type: GET_RECENT_USER,
  data
})

