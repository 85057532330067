import produce from "immer";
import {ApplicationAction,} from "store/types";
import {SummaryState, SUMMARY_FAIL, SUMMARY_LOADING, SUMMARY_SUCCESS} from "store/types/SummaryStatisticType";

const initialState: SummaryState = {
  data: null,
  loading: false,
};


const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {
    case SUMMARY_FAIL:
      return produce(state, (draft) => {

      });
    case SUMMARY_SUCCESS:
      return produce(state, (draft) => {
        draft.data = action.payload;
        draft.loading = action.loading;
      });
    case SUMMARY_LOADING:
      return produce(state, (draft) => {

      });

    default:
      return state;
  }
}
export default reducer;
