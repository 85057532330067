import React from 'react';
import {
  Formik,
  Form,
  FormikErrors,
  FormikTouched,
  FormikHelpers,
  FormikValues
} from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import styles from './FormBuilder.module.css';
import {
  ErrorHandler, QuillEditor,
} from 'components';

import { useTranslation } from 'react-i18next';

export interface FormClasses {
  readonly root?: string;
}

export interface MultiSelectOption {
  id: string | number;
  label: string | number;
  data?: any;
}

export interface SelectOption {
  readonly value?: string | number;
  readonly title?: string | number;
}

export interface RadioOption {
  readonly value?: string | number | boolean;
  readonly title?: string | number;
  readonly subtitle?: React.ReactNode;
}

export interface FormInput {
  readonly name: string;
  readonly type?:
    | 'text'
    | 'typography'
    | 'number'
    | 'auto_complete'
    | 'async_auto_complete'
    | 'password'
    | 'tel'
    | 'date'
    | 'time'
    | 'switch'
    | 'rich_editor'
    | 'date_time'
    | 'multi_item_with_quantity'
    | 'location'
    | 'checkbox'
    | 'email'
    | 'link'
    | 'large_checkbox'
    | 'select'
    | 'radio'
    | 'file_drop_zone';
  readonly label?: string;
  readonly show_paper?: boolean;
  readonly maxChar?: number;
  readonly minDate?: Date;
  readonly dateMomentFormat?: string;
  readonly maxDate?: Date;
  readonly minDateRef?: string;
  readonly description?: React.ReactNode;
  readonly placeholder?: string;
  // readonly asyncAutoCompleteProps?: AsyncAutocompleteProps;
  // readonly multiItemQuantityProps?: SettingQuantityProviderProps;
  readonly selectOptions?: SelectOption[];
  readonly radioOptions?: RadioOption[];
  readonly switchOption?: RadioOption;
  readonly variant?: 'standard' | 'outlined' | 'filled' | undefined;
  readonly startIcon?: React.ReactElement;
  readonly classname?: string;
  readonly orientation?: 'row' | 'column';
  readonly containerProps?: any;
  readonly inputProps?: any;
  readonly changeState?: Function;
  readonly autoFocus?: boolean;
  readonly setUrl?: Function;
  readonly imgSrc?: string;
  readonly fullWidth?: boolean;
  readonly autocompleteOptions?: MultiSelectOption[];
  readonly setAutocompleteSelected?: (value: MultiSelectOption) => void;
  readonly autocompleteMultiple?: boolean;
}

export interface UseFormBuilderProps {
  readonly fields: FormInput[];
  formRef?: React.RefObject<HTMLFormElement>;
  readonly validations: Object;
  readonly initialValues?: Object;
  readonly formProps?: Object;
  initialTouched?: FormikTouched<FormikValues>;
  readonly formClasses?: FormClasses;
  readonly onSubmit: (values: Object, formikHelpers: FormikHelpers<any>) => any;
  readonly renderSubmit?: (
    isSubmitting: boolean,
    errors: FormikErrors<Object>,
    touched: FormikTouched<Object>,
    setFieldTouched: Function,
    handleSubmit: Function,
    values: Object
  ) => any;
}


const FormBuilder = React.forwardRef<HTMLFormElement, UseFormBuilderProps>(
  (props, formRef) => {
    const {
      // formRef,
      initialValues,
      initialTouched = {},
      validations = {},
      formProps = {},
      formClasses = {},
      fields = [],
      onSubmit = () => {},
      renderSubmit
    } = props;
    const { t , i18n} = useTranslation();
    return (
      <Formik
        initialTouched={initialTouched}
        initialValues={initialValues || {}}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          console.log(values);
          onSubmit(values, actions);
        }}
        validationSchema={Yup.object().shape(validations as any)}
        render={({
          values,
          handleBlur,
          handleChange,
          setFieldValue,
          errors,
          setErrors,
          touched,
          setFieldTouched,
          isSubmitting,
          handleSubmit
        }) => (
          <Form
            ref={formRef}
            className={clsx(styles.root, formClasses.root)}
            {...formProps}>
            <div>
              {fields
                .filter((field) => field)
                .map(({ type = 'text', ...field }) => (
                  <div
                    
                    style={{
                      display: field?.inputProps?.hidden === true ? 'none' : '',
                      ...(field.containerProps?.style || {})
                    }}
                    {...(field.containerProps || {})}
                    component={'div'}>
                    {['rich_editor'].indexOf(type) !== -1 ? (
                        <ErrorHandler>
                          <div>
                            <QuillEditor
                                field={field}
                                placeholder={
                                  field.placeholder ? field.placeholder : ''
                                }
                                value={(values as any)[field.name]}
                                onChange={(content) =>
                                    setFieldValue(field.name, content)
                                }
                            />
                            {(touched as any)[field.name] &&
                            (errors as any)[field.name] && (
                                <div className="mb-2">
                                    {(errors as any)[field.name]}
                                </div>
                            )}
                          </div>
                        </ErrorHandler>
                    ) : ['text', 'number', 'email', 'password'].indexOf(
                        type
                      ) !== -1 ? (
                      <div className="mb-2">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                          {field.label}
                        </label>
                        <div className="mt-1">
                          <input
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                            error={Boolean(
                              (touched as any)[field.name] &&
                                (errors as any)[field.name]
                            )}
                            placeholder={field.placeholder}
                            autoFocus={field.autoFocus || false}
                            name={field.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type={type}
                            value={(values as any)[field.name]}
                            {...(field.inputProps || {})}
                          />
                        </div>
                        <p className="text-red-500 text-xs mt-1">
                          {
                            (touched as any)[field.name] &&
                            (errors as any)[field.name]
                          }
                        </p>
                      </div>
                    )
                    : ['typography'].indexOf(type) !== -1 ? (
                        <p >
                          {field?.description}
                        </p>
                    ) : (
                      <div />
                    )}
                  </div>
                ))}
              {renderSubmit &&
                renderSubmit(
                  isSubmitting,
                  errors,
                  touched,
                  setFieldTouched,
                  handleSubmit,
                  values
                )}
            </div>
          </Form>
        )}
      />
    );
  }
);

export default FormBuilder;
