import { Action } from "redux";
import { Pagination, Service, ServiceCategory } from "store/types";
import { TypeOf } from "yup";

export interface CategoryState {
    range: Pagination;
    data:  Service[];
    loading: boolean
}
export interface Category {
    id:               number;
    title:            string;
    service?:         Service[];
    service_category: ServiceCategory;
}


// export interface CategoryService {
//     id:       number;
//     title:    string;
//     services: Service[];
// }

// export interface Service {
//     id:               number;
//     title:            string;
//     service_category: ServiceCategory;
// }

// export interface ServiceCategory {
//     id:    number;
//     title: string;
//     services?: Service[];
// }

export const FETCH_CATEGORY_REQUEST = 'FETCH_CATEGORY_REQUEST';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_ERROR = 'FETCH_CATEGORY_ERROR';
export const ADD_CATEGORY_REQUEST='ADD_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_REQUEST='UPDATE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_REQUEST ='DELETE_CATEGORY_REQUEST'

export interface FetchCategoryRequest extends Action {
    type: typeof FETCH_CATEGORY_REQUEST,
    loading: boolean,
}

export interface FetchCategorySuccess extends Action {
    type: typeof FETCH_CATEGORY_SUCCESS,
    loading: boolean,
    services: Service[],
}

export interface FetchCategoryError extends Action {
    type: typeof FETCH_CATEGORY_ERROR,
    loading: boolean,
}
export interface AddCategoryAction extends Action {
    category: Category;
    type: typeof ADD_CATEGORY_REQUEST,
    loading:boolean
}
export interface UpdateCategoryAction extends Action{
    category: Category;
    type: typeof UPDATE_CATEGORY_REQUEST,
    loading:boolean
}
export interface DeleteCategoryAction extends Action{
    category: Category;
    type: typeof DELETE_CATEGORY_REQUEST,
    loading:boolean
}
