import produce from "immer";
import { ApplicationAction, } from "store/types";
import {SUMMARY_MONTH_FAIL,SUMMARY_MONTH_LOADING,SUMMARY_MONTH_SUCCESS,SummaryMonthState } from "store/types/SummaryMonthStatisticType";

const initialState: SummaryMonthState = {
    data:[],
    loading:false,
  };



  const reducer = (state = initialState, action: ApplicationAction) => {
    switch(action.type){
      case SUMMARY_MONTH_FAIL:
        return produce(state, (draft) => {
          
        });
        case SUMMARY_MONTH_SUCCESS:
          return produce(state, (draft) => {
            draft.data = [action.payload];
            draft.loading = action.loading;
          });
          case SUMMARY_MONTH_LOADING:
            return produce(state, (draft) => {
            
            });
      
      default:
        return state;
    }
  }
  export default reducer;