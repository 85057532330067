import {Action} from "redux";

export interface User {
  email: string;
  firstname: string;
  lastname: string;
  phonenumber: string;
  status: string;
  documents: Document[] | null;
  id: number;
  birthday: Date | null;
  public_id: string;
  address: Address | null;
  avatar: Avatar | null;
  account_type: string;
  date_added: string;
  date_modified: string;
  is_online?: boolean;
  last_online?: string;
  role: Role;
  gender: string;
  payment_infos: any[];
  civility_title?: string;
  languages?: string[];
  entreprise?: Entreprise;
}

export interface Entreprise {
  hrb?: string;
  firma?: string;
  ik_nr?: string;
  steuer_nr?: string;
  street?: string;
  location?: string;
  street_nr?: string;
  postal_code?: string;
  website?: string;
  ust_id_nr?: string;
}


export interface Document {
  uuid: string;
  file_name: string;
  file: File;
  date_added: Date;
  date_modified: Date;
}

export interface File {
  file_name: string;
  url: string;
  size: number;
  mimetype: string;
}

export interface Address {
  street: string;
  location: string;
  street_nr: string;
  postal_code: string;
}

export interface Avatar {
  file_name: string;
  url: string;
  size: number;
}

export interface Role {
  id: number;
  title: string;
}

export interface AddUser {
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  gender: string;
  role_id: number;
  account_type: string;
}

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const UPDATE_PASSWORD_RESET = "UPDATE_PASSWORD_RESET";
export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const LOGOUT = "LOGOUT";

export interface LoadingAccountState {
  login_loading: boolean;
  login_failed: boolean;
  login_failed_message: string;

  update_profile_loading: boolean;
  update_profile_failed: boolean;
  update_profile_success: boolean;
  update_profile_failed_message: string;

  signup_loading: boolean;
  signup_success: boolean;
  signup_failed: boolean;
  signup_failed_message: string;

  update_password_loading: boolean;
  update_password_success: boolean;
  update_password_failed: boolean;
  update_password_failed_message: string;
}

export interface IndividualStatResponse {
  notes?: Note[];
  service_stat?: ServiceStat[];
  favorites_users?: FavoriteUser[],
}

export interface FavoriteUser{
  firstname?: string;
  lastname?: string;
  email?: string;
  avatar: Avatar
}

export interface Note {
  count: number;
  note: number;
}

export interface ServiceStat {
  service_id?: number;
  number?: number;
  town?: string;
  cost_tva?: number | null;
  service_name?: string,
  service_category?: string,
}


export interface UserReviewResponse {
  total: number;
  pages: number;
  current_page: number;
  per_page: number;
  data: UserReview[];
}

export interface UserReview {
  uuid?: string;
  note?: number;
  content?: string;
  post_by?: PostBy;
  date_added?: Date;
}

export interface PostBy {
  firstname?: string;
  lastname?: string;
  email?: string;
  avatar?: PostByAvatar;
}

export interface PostByAvatar {
  file_name?: string;
  url?: string;
  size?: number;
}


export interface AccountState {
  loading: LoadingAccountState;
  token?: string;
  user?: User;
}

export interface LoginRequest extends Action {
  type: typeof LOGIN_REQUEST;
}

export interface LoginSuccess extends Action {
  type: typeof LOGIN_SUCCESS;
  token: string;
  user: User;
}

export interface LoginError extends Action {
  type: typeof LOGIN_FAILURE;
  message: string;
}

export interface SignUpRequest extends Action {
  type: typeof SIGNUP_REQUEST;
}

export interface SignUpSuccess extends Action {
  type: typeof SIGNUP_SUCCESS;
  message: string;
}

export interface SignUpError extends Action {
  type: typeof SIGNUP_FAILURE;
  message: string;
}

export interface UpdatePasswordRequest extends Action {
  type: typeof UPDATE_PASSWORD_REQUEST;
}

export interface UpdatePasswordReset extends Action {
  type: typeof UPDATE_PASSWORD_RESET;
}

export interface UpdatePasswordSuccess extends Action {
  type: typeof UPDATE_PASSWORD_SUCCESS;
  message: string;
}

export interface UpdatePasswordError extends Action {
  type: typeof UPDATE_PASSWORD_FAILURE;
  message: string;
}

export interface Logout extends Action {
  type: typeof LOGOUT;
}
