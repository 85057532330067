import {Action} from "redux";


export const SUMMARY_LOADING = "SUMMARY_LOADING"
export const SUMMARY_FAIL = "SUMMARY_FAIL"
export const SUMMARY_SUCCESS = "SUMMARY_SUCCESS"

export interface SummaryState {
  data: SummaryType | null;
  loading: boolean;
}


export interface SummaryType {
  nb_user_admin: number,
  nb_user_helfer: number,
  nb_user_senior: number,
  helfer: SummaryCardData;
  senior: SummaryCardData;
  request_complete: SummaryCardData;
  total_service: SummaryCardData;
  used_service: SummaryCardData;
  ordered_request: SummaryCardData;
  frequent_users: {
    nb_total_of_users: number,
    users: SummaryUser[],
  },
  frequent_services: {
    nb_total_of_services: number,
    services: SummaryService[]
  }
  nb_request_complete: number,
  nb_total_service: number
}

export interface SummaryCardData {
  total?: number;
  last_week?: number;
  this_week?: number;
  last_two_week?: number;
  this_two_week?: number;
  last_month?: number;
  this_month?: number;
  last_year?: number;
  this_year?: number;
}


export interface SummaryUser {
  rank: number,
  user: {
    email: String,
    firstname: String,
    lastname: String,
    public_id: String
  }
}

export interface SummaryService {
  rank: number,
  service: {
    id: number,
    title: String
  },
  request_number: number
}


export interface SummaryLoading extends Action {
  type: typeof SUMMARY_LOADING
}

export interface SummaryFail extends Action {
  type: typeof SUMMARY_FAIL
}

export interface SummarySuccess extends Action {
  type: typeof SUMMARY_SUCCESS,
  loading: boolean;
  payload: SummaryType
}






