import { Action } from "redux";
import { BooleanSchema } from "yup";
import { boolean } from "yup/lib/locale";

export const REQUEST_BY_CITY_START = "REQUEST_BY_CITY_START";
export const REQUEST_BY_CITY_SUCCESS = "REQUEST_BY_CITY_SUCCESS";
export const REQUEST_BY_CITY_ERROR = "REQUEST_BY_CITY_ERROR";

export interface RequestByCity {
  town: string;
  request_number: number;
}

export interface RequestByCityStatisticsState {
  loading: boolean;
  data: RequestByCity[];
}

export interface RequestByCityStart extends Action {
  type: typeof REQUEST_BY_CITY_START;
}

export interface RequestByCitySuccess extends Action {
  type: typeof REQUEST_BY_CITY_SUCCESS;
  data: RequestByCity[];
}

export interface RequestByCityError extends Action {
  type: typeof REQUEST_BY_CITY_ERROR;
}
