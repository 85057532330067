import React from 'react';
import { Redirect } from 'react-router-dom';
import SecureLS from 'secure-ls';
const ls = new SecureLS({ encodingType: 'aes' });

function GuestGuard(props: any) {
  const { children } = props;

  let token: any;
  try {
    token = ls.get('token');
  } catch (error) {}
  if (token) {
    return <Redirect to="/app/home" />;
  }


  return children;
}

export default GuestGuard;
