import produce from "immer";
import {ApplicationAction} from "store/types";
import {
  FETCH_REQUEST_START,
  FETCH_REQUEST_ERROR,
  FETCH_REQUEST_SUCCESS,
  RequestState, GET_RECENT_REQUEST,
} from "store/types/RequestType";

const initialState: RequestState = {
  request: {
    data: [],
    current_page: 1,
    pages: 1,
    per_page: 18,
    total: 0,
  },
  range: {
    end_date: "",
    order_field: "",
    start_date: "",
    status: "",
    role_id: "",
    current_page: 1,
    pages: 1,
    per_page: 18,
    total: 0,
    keyword: "",
  },
  loading: false,
  recent_request: []
};

const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {
    case FETCH_REQUEST_START:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case FETCH_REQUEST_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.request = action.data;
      });
    case FETCH_REQUEST_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
      });
    case GET_RECENT_REQUEST:
      return produce(state, (draft) => {
        draft.recent_request = action.data;
      });

    default:
      return state;
  }
};
export default reducer;
