export default {
  translation: {
    add_user: "Nutzer hinzufügen",
    login_form_email: "Email",
    login_form_firstname: "Vorname",
    login_form_lastname: "Nachname",
    login_form_password: "Passwort",
    dashboard: "Armaturenbrett",
    user_title_page: "Benutzer",
    categorie_title_page: "Kategorie",
    add_category_button: "Kategorie hinzufügen",
    numbers_of_users: "Anzahl der Nutzer",
    view_all: "Alles sehen",
    most_wanted_service: "Am häufigsten nachgefragte Dienste",
    tab_rank: "Rang",
    tab_service_name: "Dienstname",
    tab_demand_number: "Anzahl der Anfragen",
    page_title_category: "Kategorien Dienstleistungen",
    page_title_command: "Auftragsmanagement",
    page_title_data: "Stammdatenmanagement",
    KLEIN_UNTERNEHMER: "Klein Unternehmer",
    GEWERBE: "Gewerbe",
    MITARBEITER: "Mitarbeiter",
    EHRENAMTLICHE_ALLTAGSHELFER: "Ehrenamtliche Alltagshelfer",
    street_nr: "Nr.",
    town: "Nr.",
    postal_code: "Postleitzahl",
    more_infos: "Mehr Informationen",
    location: "Standort",
    stats_amount_spend_by_seniors_by_month:
      "Monatliche Ausgabenstatistik für Senioren",
    tab_turnover: "Umsatz",
    most_profitable_service: "Die profitabelsten Dienste",
    most_profitable_hour: "Die Stunden mit den meisten Serviceanfragen",
    tab_hour: "Zeit",
    service_request: "Anfragen",
    CANCELLED: "Abgesagt",
    PENDING: "Offen",
    PENDING_HELFER: "Offen",
    OUTSTANDING: "Im Gange",
    COMPLETED: "Fertig",
    REJECTED_BY_ALL: "Von allen abgelehnt",
    EXPIRED: "Erlöschen",
    PAUSED: "Pausiert",
    PAID: "Bezahlt",
    FAILDED: "Scheitern",
    ALL: "Alle",
    "last_week": "Letzte Woche",
    "last_two_week": "Die letzten 14 Tage",
    "last_month": "Letzter Monat",
    "last_year": "Das letzte Jahr",
    "this_year": "In diesem Jahr",
    "total": "Gesamt",
    "shared.internet_connexion_error": "Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut",
    ANDERE: "Andere",
    FRAU: "Frau",
    HERR: "Herr"
  },
};
