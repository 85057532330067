import { AddServiceAction, ADD_SERVICES_REQUEST, DeleteServiceAction, DELETE_SERVICES_REQUEST, FetchServicesError, FetchServicesRequest, FetchServicesSuccess, FETCH_SERVICES_ERROR, FETCH_SERVICES_REQUEST, FETCH_SERVICES_SUCCESS, Service, UpdateService, UpdateServiceAction, UPDATE_SERVICES_REQUEST } from "store/types";

export const fetchServiceRequest = (loading: boolean) : FetchServicesRequest => (
    {
        type: FETCH_SERVICES_REQUEST,
        loading: loading,
    }
)

export const fetchServicesSuccess = (loading: boolean, services: Service[]) : FetchServicesSuccess => ({
    type: FETCH_SERVICES_SUCCESS,
    loading: loading,
    services: services
})

export const fetchServicesError = (loading: boolean) : FetchServicesError => ({
    type: FETCH_SERVICES_ERROR,
    loading: loading
})

export const addServiceAction = (loading:boolean, service:Service): AddServiceAction =>({
    type: ADD_SERVICES_REQUEST,
    loading:loading,
    service: service
})
export const updateServiceAction = (loading:boolean, service:Service): UpdateServiceAction =>({
    type: UPDATE_SERVICES_REQUEST,
    loading:loading,
    service: service
})
export const deleteServiceAction = (loading:boolean, service:Service): DeleteServiceAction =>({
    type: DELETE_SERVICES_REQUEST,
    loading:loading,
    service: service
})