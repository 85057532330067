import {ApplicationAction} from "store/types";
import {
  FETCH_MONEY_SPEND_BY_SENIOR_ERROR,
  FETCH_MONEY_SPEND_BY_SENIOR_REQUEST,
  FETCH_MONEY_SPEND_BY_SENIOR_SUCCESS,
  FETCH_MOST_HOUR_FOR_REQUEST_SERVICE_ERROR,
  FETCH_MOST_HOUR_FOR_REQUEST_SERVICE_REQUEST,
  FETCH_MOST_HOUR_FOR_REQUEST_SERVICE_SUCCESS,
  FETCH_MOST_MONEY_STATS_BY_SERVICE_ERROR,
  FETCH_MOST_MONEY_STATS_BY_SERVICE_REQUEST,
  FETCH_MOST_MONEY_STATS_BY_SERVICE_SUCCESS,
  SET_BAFIN_STATISTICS,
  SET_KPI_STATISTICS,
  SET_USERS_BY_LOCALISATION,
  StatisticsState,
} from "store/types/StatisticsTypes";
import produce from "immer";

const initialState: StatisticsState = {
  loading: {
    loading_money_spend_by_senior_error: false,
    loading_money_spend_by_senior_request: false,
    loading_money_spend_by_senior_success: false,
    loading_most_hour_for_request_service_error: false,
    loading_most_hour_for_request_service_request: false,
    loading_most_hour_for_request_service_success: false,
    loading_most_money_stats_by_service_error: false,
    loading_most_money_stats_by_service_request: false,
    loading_most_money_stats_by_service_success: false,
  },
  statistics: {
    money_spend_by_senior_stats: [],
    most_hour_for_request_service_stats: [],
    most_money_stats_by_service: [],
  },
  kpi: {
  },
  bafin: {

  },
  usersByLocalisation: {
    helfers: [],
    seniors: []
  }
};

const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {
    case FETCH_MOST_MONEY_STATS_BY_SERVICE_REQUEST:
      return produce(state, (draft) => {
        draft.loading.loading_most_money_stats_by_service_request = true;
        draft.loading.loading_most_money_stats_by_service_success = false;
        draft.loading.loading_most_money_stats_by_service_error = false;
      });
    case FETCH_MOST_MONEY_STATS_BY_SERVICE_SUCCESS:
      return produce(state, (draft) => {
        draft.loading.loading_most_money_stats_by_service_request = false;
        draft.loading.loading_most_money_stats_by_service_success = true;
        draft.loading.loading_most_money_stats_by_service_error = false;
        draft.statistics.most_money_stats_by_service = action.data;
      });
    case FETCH_MOST_MONEY_STATS_BY_SERVICE_ERROR:
      return produce(state, (draft) => {
        draft.loading.loading_most_money_stats_by_service_request = false;
        draft.loading.loading_most_money_stats_by_service_success = false;
        draft.loading.loading_most_money_stats_by_service_success = true;
      });

    case FETCH_MOST_HOUR_FOR_REQUEST_SERVICE_REQUEST:
      return produce(state, (draft) => {
        draft.loading.loading_most_hour_for_request_service_request = true;
        draft.loading.loading_most_hour_for_request_service_success = false;
        draft.loading.loading_most_hour_for_request_service_error = false;
      });
    case FETCH_MOST_HOUR_FOR_REQUEST_SERVICE_SUCCESS:
      return produce(state, (draft) => {
        draft.loading.loading_most_hour_for_request_service_request = true;
        draft.loading.loading_most_hour_for_request_service_success = true;
        draft.loading.loading_most_hour_for_request_service_error = false;
        draft.statistics.most_hour_for_request_service_stats = action.data;
      });
    case FETCH_MOST_HOUR_FOR_REQUEST_SERVICE_ERROR:
      return produce(state, (draft) => {
        draft.loading.loading_most_hour_for_request_service_request = true;
        draft.loading.loading_most_hour_for_request_service_success = true;
        draft.loading.loading_most_hour_for_request_service_error = false;
      });

    case SET_KPI_STATISTICS:
      return produce(state, (draft) => {
        draft.kpi = action.kpi;
      });

    case SET_USERS_BY_LOCALISATION:
      return produce(state, (draft) => {
        draft.usersByLocalisation = action.localisation;
      });

    case SET_BAFIN_STATISTICS:
      return produce(state, (draft) => {
        draft.bafin = action.bafin;
      });

    case FETCH_MONEY_SPEND_BY_SENIOR_REQUEST:
      return produce(state, (draft) => {
        draft.loading.loading_money_spend_by_senior_request = true;
        draft.loading.loading_money_spend_by_senior_success = false;
        draft.loading.loading_money_spend_by_senior_error = false;
      });
    case FETCH_MONEY_SPEND_BY_SENIOR_SUCCESS:
      return produce(state, (draft) => {
        draft.loading.loading_money_spend_by_senior_request = false;
        draft.loading.loading_money_spend_by_senior_success = true;
        draft.loading.loading_money_spend_by_senior_error = false;
        draft.statistics.money_spend_by_senior_stats = action.data;
      });
    case FETCH_MONEY_SPEND_BY_SENIOR_ERROR:
      return produce(state, (draft) => {
        draft.loading.loading_money_spend_by_senior_request = false;
        draft.loading.loading_money_spend_by_senior_success = false;
        draft.loading.loading_money_spend_by_senior_error = true;
      });

    default:
      return state;
  }
};

export default reducer;
