import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {SplashScreen} from 'components';
import {setTranslationFunction} from 'store/actions';
import {useTranslation} from 'react-i18next';

function Auth(props: any) {
  const { children } = props;
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setTranslationFunction(t));
  }, [dispatch, t]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

export default Auth;
