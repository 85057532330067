import React from 'react';

function SlashScreen() {

  return (
    <div >
        <img alt="Logo" src="/logo.svg" />
        Loading...
    </div>
  );
}

export default SlashScreen;
