import produce from 'immer';
import {ApplicationAction} from 'store/types';
import {ProtectionUserState} from "../types/ProtectionUserType";
//cli_import_section


const initialState: ProtectionUserState = {
  payload: {
    page: 1,
    keyword: '',
    per_page: 10
  }
}


const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {
//cli_use_cases_section

    default: {
      return state;
    }
  }
};

export default reducer;