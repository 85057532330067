import {Action} from "redux";
import {Address, Role, User} from "store/types";

export interface UsersState {
  range: Pagination;
  data: User[];
  recent_user: User[];
}

export interface Pagination {
  total?: number;
  pages?: number;
  page?: number;
  current_page?: number;
  per_page?: number;
  keyword?: string;
  status?: string;
  service_id?: number;
  role_id?: string;
}

export interface UpdateUser {
  public_id: string;
  firstname?: string;
  lastname?: string;
  phonenumber?: string;
  gender?: string;
  civility_title?: string;
  birthday?: string;
  email?: string;
  address?: Address;
  role_id?: number;
  account_type?: string;
}

export interface DeleteUser {
  email: string;
  public_id: string;
  firstname: string;
  lastname: string;
  role: number;
  gender: string;
}

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const ADD_USER_ACTION = "ADD_USER_ACTION";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const DELETE_USER_ACTION = "DELETE_USER_ACTION";
export const GET_RECENT_USER = "GET_RECENT_USER";

export interface FetchUserRequest extends Action {
  type: typeof FETCH_USER_REQUEST;
  users: User[];
  pages: number;
  total: number;
  page: number;
}

export interface AddUserAction extends Action {
  type: typeof ADD_USER_ACTION;
  user: User;
}

export interface UpdateUserSuccess extends Action {
  type: typeof UPDATE_USER_SUCCESS;
  user: User;
}

export interface DeleteUserAction extends Action {
  type: typeof DELETE_USER_ACTION;
  public_id: String;
}


export interface GetRecentUserAction extends Action {
  type: typeof GET_RECENT_USER;
  data: User[];
}
