/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, {Fragment, lazy, Suspense} from "react";
import AuthLayout from "layouts/Auth";
import ApplicationLayout from "layouts/Application";
import {RouteConfig} from "react-router-config";
import {Redirect, Route, Switch} from "react-router-dom";
import {LoadingScreen} from "components";
import AuthGuard from "./AuthGuard";
import GuestGuard from "./GuestGuard";
// import {Router} from "@material-ui/icons";

const routesConfig: RouteConfig[] = [
    {
        path: "/",
        exact: true,
        component: () => <Redirect to="/app/home"/>,
    },
    {
        path: "/auth",
        layout: AuthLayout,
        guard: GuestGuard,
        routes: [
            {
                path: "/auth/login",
                exact: true,
                component: lazy(() => import("views/auth/login/Login.page")),
            },
            {
                component: () => <Redirect to="/errors/error-404"/>,
            },
        ],
    },
    {
        path: "/app",
        layout: ApplicationLayout,
        guard: AuthGuard,
        routes: [
            {
                path: "/app/home",
                exact: true,
                component: lazy(() => import("views/home/Home.page")),
            },
            {
                path: "/app/statistiken",
                exact: true,
                component: lazy(() => import("views/statistics/Statistics.page")),
            },
            /*{
                path: "/app/fakten",
                exact: true,
                component: lazy(() => import("views/kpi/Kpi.page")),
            },*/
            {
                path: "/app/anfragen",
                exact: true,
                component: lazy(() => import("views/request/Request.page")),
            },
            {
                path: "/app/anwender",
                exact: true,
                component: lazy(() => import("views/users/User.page")),
            },
            {
                path: "/app/services",
                exact: true,
                component: lazy(() => import("views/services/Services.page")),
            },
            {
                path: "/app/categories",
                exact: true,
                component: lazy(() => import("views/category/Category.page")),
            },
            {
                path: "/app/schutz-daten",
                exact: true,
                component: lazy(() => import("views/protection/Protection.page")),
            },
            {
                path: "/app/bafin",
                exact: true,
                component: lazy(() => import("views/bafin/Bafin.page")),
            },
        ],
    },
];

const renderRoutes = (routes: RouteConfig[]) =>
    routes ? (
        <Suspense fallback={<LoadingScreen/>}>
            <Switch>
                {routes.map((route, i) => {
                    const Guard = route.guard || Fragment;
                    const Layout = route.layout || Fragment;
                    const Component: any = route.component;
                    return (
                        <Route
                            key={i}
                            path={route.path}
                            exact={route.exact}
                            render={(props) => (
                                <Guard>
                                    <Layout>
                                        {route.routes ? (
                                            renderRoutes(route.routes)
                                        ) : (
                                            <Component {...props} />
                                        )}
                                    </Layout>
                                </Guard>
                            )}
                        />
                    );
                })}
            </Switch>
        </Suspense>
    ) : null;

function Routes() {
    return renderRoutes(routesConfig);
}

export default Routes;
