import {Action} from "redux";
import {Avatar, Pagination, Role, User} from "store/types";
import internal from "stream";
import {TypeOf} from "yup";

export interface RequestState {
  loading: boolean;
  request: RequestResults;
  range: RequestParameters;
  recent_request: RequestService[];
}

export interface RequestResults {
  data: RequestService[];
  total: number;
  pages: number;
  per_page: number;
  current_page: number;
}

export interface RequestParameters {
  total?: number;
  pages?: number;
  current_page?: number;
  per_page?: number;
  keyword?: string;
  role_id?: string;
  order_field: string;
  service_id?: number | null;
  start_date: string;
  end_date: string;
  status: string;
  service_finished?: boolean | null;
}

type payment_status = "PAID" | "PENDING" | "FAILDED"

export interface RequestService {
  uuid?: string;
  cost?: number;
  cost_tva?: number;
  payment_status: payment_status,
  status: Status;
  request_by?: AcceptBy;
  id?: string;
  accept_by?: AcceptBy;
  service?: Service;
  comment?: string;
  request_by_contact?: {
    uuid?: string;
    address_description?: string;
    vor_nachname?: string;
    strabe?: string;
    nr?: string;
    plz?: string;
    stadt?: string;
    email?: string;
    telefonnummer?: string;
    verwandtenschaft?: string;
    localisation?: string;
    date_added?: Date;
    date_modified?: Date;
  };
  numbers_of_sending?: number;
  date_added?: Date;
  date_modified?: Date;
  users_coordinates?: UsersCoordinate[];
  rejected_by?: any[];
  coordinate?: number[];
  current_accept_coordinate?: number[];
  accept_at?: Date;
  last_update_coordinate_at?: Date;
  arrived_at?: Date;
  service_started?: boolean;
  service_finished?: boolean;
  finished_at?: Date;
  senior_give_review?: boolean;
  helfer_give_review?: boolean;
  realization_requests?: any[];
  reviews?: Review[];
  total: number;
  pages: number;
  current_page: number;
  per_page: number;
}

export interface AcceptBy {
  public_id?: string;
  id?: number;
  firstname?: string;
  lastname?: string;
  email?: string;
  avatar?: Avatar;
  address?: Address | null;
  entreprise?: Entreprise | null;
  phonenumber?: string;
  languages?: string[] | null;
}

export interface Address {
  street?: string;
  location?: string;
  street_nr?: string;
  postal_code?: string;
  address?: string;
}


export interface Entreprise {
  hrb?: string;
  firma?: string;
  ik_nr?: string;
  steuer_nr?: string;
  street?: string;
  location?: string;
  street_nr?: string;
  postal_code?: string;
  website?: string;
  ust_id_nr?: string;
}

export interface Review {
  uuid?: string;
  note?: number;
  content?: string;
  add_user_favorite?: boolean;
  post_by?: AcceptBy;
  post_for?: AcceptBy;
}

export interface Service {
  id?: number;
  title?: string;
  service_category?: ServiceCategory;
}

export interface ServiceCategory {
  id?: number;
  title?: string;
}

export interface UsersCoordinate {
  lat?: number;
  lng?: number;
  avatar?: Avatar;
  lastname?: string;
  firstname?: string;
  user_public_id?: string;
}


export enum Status {
  Completed = "COMPLETED",
  Outstanding = "OUTSTANDING",
  Pending = "PENDING",
  PendingHelfer = "PENDING_HELFER",
}

export const FETCH_REQUEST_START = "FETCH_REQUEST_START";
export const FETCH_REQUEST_SUCCESS = "FETCH_REQUEST_SUCCESS";
export const FETCH_REQUEST_ERROR = "FETCH_REQUEST_ERROR";
export const GET_RECENT_REQUEST = "GET_RECENT_REQUEST";

export interface FetchRequestStart extends Action {
  type: typeof FETCH_REQUEST_START;
}

export interface FetchRequestSuccess extends Action {
  type: typeof FETCH_REQUEST_SUCCESS;
  data: RequestResults;
}

export interface GetRecentRequest extends Action {
  type: typeof GET_RECENT_REQUEST;
  data: RequestService[];
}

export interface FetchServiceRequestError extends Action {
  type: typeof FETCH_REQUEST_ERROR;
}
