import { ApplicationAction } from "store/types";
import produce from "immer";
import {
  RequestByCityStatisticsState,
  REQUEST_BY_CITY_ERROR,
  REQUEST_BY_CITY_START,
  REQUEST_BY_CITY_SUCCESS,
} from "store/types/RequestByCItyStatiticTypes";

const initialState: RequestByCityStatisticsState = {
  data: [],
  loading: false,
};

const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {
    case REQUEST_BY_CITY_START:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case REQUEST_BY_CITY_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.data = action.data;
      });
    case REQUEST_BY_CITY_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
      });
    default:
      return state;
  }
};

export default reducer;
