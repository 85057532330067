import { Action } from "redux";
import {SelectOption} from "../../components/Select/Select";


export interface BafinPayload {
  year: SelectOption
  month: SelectOption
}

export interface BafinResponse{
  "id": string,
  "public_id": string,
  "name": string,
  "duration": string,
  "cost": number
}


export interface UserByLocalisationResponse {
  seniors: UserByLocalisation[];
  helfers: UserByLocalisation[];
}

export interface UserByLocalisation {
  town:         string;
  user_number:  number;
  localisation: Localisation;
}

export interface Localisation {
  lat: number;
  lng: number;
}


export interface StatisticsState {
  loading: StatisticsLoading;
  statistics: GlobalStatistics;
  kpi: KpiStatistics;
  usersByLocalisation: UserByLocalisationResponse;
  bafin: {
    payload?: BafinPayload
    response?: BafinResponse[]
  }
}
export interface KpiStatistics {
  helfer_ratio_senior?:            number;
  senior_ratio_helfer?:            number;
  average_duration_of_help?:       number;
  average_spontaneity?:            number;
  average_costs_per_intervention?: number;
  spontaneity_1?:                  number;
  spontaneity_2?:                  number;
  spontaneity_3?:                  number;
  global_service_time?:            number;
  global_work_time?:               number;
  moy_fees_per_service?:               number;
}

export const FETCH_MOST_MONEY_STATS_BY_SERVICE_REQUEST =
  "FETCH_MOST_MONEY_STATS_BY_SERVICE_REQUEST";
export const FETCH_MOST_MONEY_STATS_BY_SERVICE_SUCCESS =
  "FETCH_MOST_MONEY_STATS_BY_SERVICE_SUCCESS";
export const FETCH_MOST_MONEY_STATS_BY_SERVICE_ERROR =
  "FETCH_MOST_MONEY_STATS_BY_SERVICE_ERROR";

export const FETCH_MOST_HOUR_FOR_REQUEST_SERVICE_REQUEST =
  "FETCH_MOST_HOUR_FOR_REQUEST_SERVICE_REQUEST";
export const FETCH_MOST_HOUR_FOR_REQUEST_SERVICE_SUCCESS =
  "FETCH_MOST_HOUR_FOR_REQUEST_SERVICE_SUCCESS";
export const FETCH_MOST_HOUR_FOR_REQUEST_SERVICE_ERROR =
  "FETCH_MOST_HOUR_FOR_REQUEST_SERVICE_ERROR";

export const FETCH_MONEY_SPEND_BY_SENIOR_REQUEST =
  "FETCH_MONEY_SPEND_BY_SENIOR_REQUEST";
export const FETCH_MONEY_SPEND_BY_SENIOR_SUCCESS =
  "FETCH_MONEY_SPEND_BY_SENIOR_SUCCESS";
export const FETCH_MONEY_SPEND_BY_SENIOR_ERROR =
  "FETCH_MONEY_SPEND_BY_SENIOR_ERROR";

export const SET_KPI_STATISTICS =
  "SET_KPI_STATISTICS";

export const SET_BAFIN_STATISTICS = "SET_BAFIN_STATISTICS";

export const SET_USERS_BY_LOCALISATION = "SET_USERS_BY_LOCALISATION";

export interface StatisticsLoading {
  loading_most_money_stats_by_service_request: boolean;
  loading_most_money_stats_by_service_success: boolean;
  loading_most_money_stats_by_service_error: boolean;
  loading_most_hour_for_request_service_request: boolean;
  loading_most_hour_for_request_service_success: boolean;
  loading_most_hour_for_request_service_error: boolean;
  loading_money_spend_by_senior_request: boolean;
  loading_money_spend_by_senior_success: boolean;
  loading_money_spend_by_senior_error: boolean;
}

export interface GlobalStatistics {
  most_money_stats_by_service: MostMoneyStatsByService[];
  money_spend_by_senior_stats: number[];
  most_hour_for_request_service_stats: MostHourForRequestService[];
}

export interface MostHourForRequestService {
  hour: string;
  request_number: number;
}

export interface MostMoneyStatsByService {
  rank: number;
  service: Service;
  request_number: number;
  turnover: number;
}

interface Service {
  id: number;
  title: string;
}

export interface FetchMostMoneyStatsByServiceRequest extends Action {
  type: typeof FETCH_MOST_MONEY_STATS_BY_SERVICE_REQUEST;
}

export interface SetKpiStatistic extends Action {
  type: typeof SET_KPI_STATISTICS;
  kpi: KpiStatistics;
}


export interface SetUsersByLocalisation extends Action {
  type: typeof SET_USERS_BY_LOCALISATION;
  localisation: UserByLocalisationResponse;
}


export interface SetBafinStatistic extends Action {
  type: typeof SET_BAFIN_STATISTICS;
  bafin: StatisticsState['bafin'];
}

export interface FetchMostMoneyStatsByServiceSuccess extends Action {
  type: typeof FETCH_MOST_MONEY_STATS_BY_SERVICE_SUCCESS;
  data: MostMoneyStatsByService[];
}

export interface FetchMostMoneyStatsByServiceError extends Action {
  type: typeof FETCH_MOST_MONEY_STATS_BY_SERVICE_ERROR;
}

export interface FetchMostHourForRequestServiceRequest extends Action {
  type: typeof FETCH_MOST_HOUR_FOR_REQUEST_SERVICE_REQUEST;
}

export interface FetchMostHourForRequestServiceSuccess extends Action {
  type: typeof FETCH_MOST_HOUR_FOR_REQUEST_SERVICE_SUCCESS;
  data: MostHourForRequestService[];
}

export interface FetchMostHourForRequestServiceError extends Action {
  type: typeof FETCH_MOST_HOUR_FOR_REQUEST_SERVICE_ERROR;
}

export interface FetchMoneySpendBySeniorRequest extends Action {
  type: typeof FETCH_MONEY_SPEND_BY_SENIOR_REQUEST;
}

export interface FetchMoneySpendBySeniorSuccess extends Action {
  type: typeof FETCH_MONEY_SPEND_BY_SENIOR_SUCCESS;
  data: number[];
}

export interface FetchMoneySpendBySeniorError extends Action {
  type: typeof FETCH_MONEY_SPEND_BY_SENIOR_ERROR;
}
