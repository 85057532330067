import { Action } from "redux";
import { number } from "yup/lib/locale";


export const  SUMMARY_MONTH_LOADING = "SUMMARY_MONTH_LOADING"
export const  SUMMARY_MONTH_FAIL = "SUMMARY_MONTH_FAIL"
export const  SUMMARY_MONTH_SUCCESS = "SUMMARY_MONTH_SUCCESS"

export interface SummaryMonthState {
 data:SummaryMonthType[]
  loading: boolean;
}


export interface  SummaryMonthType{
  seniors: SummaryMonthType[];
  helfers: SummaryMonthType[];
} 




export interface SummaryMonthLoading  extends Action {
  type:typeof SUMMARY_MONTH_LOADING
}

export interface SummaryMonthFail  extends Action {
  type:typeof SUMMARY_MONTH_FAIL
}
export interface SummaryMonthSuccess  extends Action {
  type:typeof SUMMARY_MONTH_SUCCESS,
  loading: boolean;
  payload:SummaryMonthType
}






