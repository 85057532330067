import {
  User,
  LoginError,
  LoginRequest,
  LoginSuccess,
  SignUpError,
  SignUpRequest,
  SignUpSuccess,
  UpdatePasswordError,
  UpdatePasswordRequest,
  UpdatePasswordSuccess,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  SIGNUP_REQUEST,
  SIGNUP_FAILURE,
  SIGNUP_SUCCESS,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_SUCCESS,
  Logout,
  LOGOUT
} from 'store/types';
import {
  UpdatePasswordReset,
  UPDATE_PASSWORD_RESET
} from 'store/types/AccountTypes';

export const loginRequest = (): LoginRequest => ({
  type: LOGIN_REQUEST
});

export const loginSuccess = (
  user: User,
  token: string
): LoginSuccess => ({
  type: LOGIN_SUCCESS,
  user,
  token
});

export const loginError = (message: string): LoginError => ({
  type: LOGIN_FAILURE,
  message
});

export const signUpRequest = (): SignUpRequest => ({
  type: SIGNUP_REQUEST
});

export const signUpSuccess = (message: string): SignUpSuccess => ({
  type: SIGNUP_SUCCESS,
  message
});

export const signUpError = (message: string): SignUpError => ({
  type: SIGNUP_FAILURE,
  message
});

export const updatePasswordRequest = (): UpdatePasswordRequest => ({
  type: UPDATE_PASSWORD_REQUEST
});

export const updatePasswordReset = (): UpdatePasswordReset => ({
  type: UPDATE_PASSWORD_RESET
});

export const updatePasswordSuccess = (
  message: string
): UpdatePasswordSuccess => ({
  type: UPDATE_PASSWORD_SUCCESS,
  message
});

export const updatePasswordError = (message: string): UpdatePasswordError => ({
  type: UPDATE_PASSWORD_FAILURE,
  message
});
export const logout = (): Logout => ({
  type: LOGOUT
});
