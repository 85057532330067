import { Action } from "redux";
import { Pagination, Role, User } from "store/types";
import { TypeOf } from "yup";

export interface ServicesState {
    range: Pagination;
    data:  Service[];
    loading: boolean
}

export interface Service {
    id:               number;
    title:            string;
    service?:         Service[];
    service_category: ServiceCategory;
}
export interface UpdateService {
    title:            string;
    id:               number;
    service_category_id: number;
}
export interface DeleteService {
    title:            string;
    id:               number;
    service_category_id: number;
}

export interface ServiceCategory {
    id:    number;
    title: string;
}
export interface ServiceStatistic {
    id:    number;
    title: string;
}

export const FETCH_SERVICES_REQUEST = 'FETCH_SERVICES_REQUEST';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_ERROR = 'FETCH_SERVICES_ERROR';
export const ADD_SERVICES_REQUEST = 'ADD_SERVICES_REQUEST';
export const UPDATE_SERVICES_REQUEST = 'UPDATE_SERVICES_REQUEST';
export const DELETE_SERVICES_REQUEST = 'DELETE_SERVICES_REQUEST';



export interface FetchServicesRequest extends Action {
    type: typeof FETCH_SERVICES_REQUEST,
    loading: boolean,
}

export interface FetchServicesSuccess extends Action {
    type: typeof FETCH_SERVICES_SUCCESS,
    loading: boolean,
    services: Service[],
}

export interface FetchServicesError extends Action {
    type: typeof FETCH_SERVICES_ERROR,
    loading: boolean,
}
export interface AddServiceAction extends Action {
    service: Service;
    type: typeof ADD_SERVICES_REQUEST,
    loading:boolean
}
export interface UpdateServiceAction extends Action {
    service: Service;
    type: typeof UPDATE_SERVICES_REQUEST,
    loading:boolean
}
export interface DeleteServiceAction extends Action {
    service: Service;
    type: typeof DELETE_SERVICES_REQUEST,
    loading:boolean
}
