import { ApplicationAction, ServicesState } from "store/types";
import produce from "immer";
import {
  ADD_CATEGORY_REQUEST,
  Category,
  DELETE_CATEGORY_REQUEST,
  FETCH_CATEGORY_ERROR,
  FETCH_CATEGORY_REQUEST,
  FETCH_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_REQUEST,
} from "store/types/CategoryType";

const initialState: ServicesState = {
  data: [],
  loading: false,
  range: {
    current_page: 1,
    pages: 1,
    per_page: 20,
    total: 0,
    keyword: "",
  },
};

const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {
    case FETCH_CATEGORY_REQUEST:
      return produce(state, (draft) => {
        draft.loading = action.loading;
      });
    case FETCH_CATEGORY_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = action.loading;
        draft.data = action.services;
      });
    case FETCH_CATEGORY_ERROR:
      return produce(state, (draft) => {
        draft.loading = action.loading;
      });
    case ADD_CATEGORY_REQUEST:
      var category: Category[] = [...state.data];
      category.push(action.category);
      console.log("dans le reducer action users: ", category.length);
      console.log("dans le reducer action state: ", state.data.length);
      return produce(state, (draft) => {
        draft.data = category;
      });

    case UPDATE_CATEGORY_REQUEST:
      var category: Category[] = [...state.data];
      category = category.map((category) => {
        if (category.id == action.category.id) {
          return action.category;
        } else {
          return category;
        }
      });
      return produce(state, (draft) => {
        draft.data = category;
      });

    case DELETE_CATEGORY_REQUEST:
      var category: Category[] = [...state.data];
      category = category.filter(
        (category) => !(category.id == action.category.id)
      );
      return produce(state, (draft) => {
        draft.data = category;
      });

    default:
      return state;
  }
};
export default reducer;
