import React, { useState } from 'react';
import clsx from 'clsx';
import ReactQuill from 'react-quill';
import { FormInput } from 'components/FormBuilder';
import * as Quill from "quill";

// const useStyles = makeStyles((theme: Theme) => ({
//   root: {
//     minHeight: 300,
//     '& .ql-toolbar': {
//       borderLeft: 'none',
//       borderTop: 'none',
//       borderRight: 'none',
//       borderBottom: `1px solid #E0E0E0`,
//       '& .ql-picker-label:hover': {
//         color: theme.palette.secondary.main
//       },
//       '& .ql-picker-label.ql-active': {
//         color: theme.palette.secondary.main
//       },
//       '& .ql-picker-item:hover': {
//         color: theme.palette.secondary.main
//       },
//       '& .ql-picker-item.ql-selected': {
//         color: theme.palette.secondary.main
//       },
//       '& button:hover': {
//         color: theme.palette.secondary.main,
//         '& .ql-stroke': {
//           stroke: theme.palette.secondary.main
//         }
//       },
//       '& button:focus': {
//         color: theme.palette.secondary.main,
//         '& .ql-stroke': {
//           stroke: theme.palette.secondary.main
//         }
//       },
//       '& button.ql-active': {
//         '& .ql-stroke': {
//           stroke: theme.palette.secondary.main
//         }
//       },
//       '& .ql-stroke': {
//         stroke: theme.palette.text.primary
//       },
//       '& .ql-picker': {
//         color: theme.palette.text.primary
//       },
//       '& .ql-picker-options': {
//         padding: theme.spacing(2),
//         backgroundColor: theme.palette.background.default,
//         border: 'none',
//         boxShadow: theme.shadows[10],
//         borderRadius: theme.shape.borderRadius
//       }
//     },
//     '& .ql-container': {
//       border: 'none',
//       '& .ql-editor': {
//         fontFamily: theme.typography.fontFamily,
//         fontSize: 16,
//         color: theme.palette.text.primary,
//         '&.ql-blank::before': {
//           color: theme.palette.text.secondary
//         }
//       }
//     }
//   },
//   stepButton: {
//     '& + &': {
//       marginLeft: theme.spacing(2)
//     }
//   }
// }));

interface QuillEditorProps {
  className?: string;
  value: string;
  placeholder?: string;
  theme?: string;
  field: FormInput;
  onChange: (content: string) => void;
}

function QuillEditor(props: QuillEditorProps) {
  const {
    className,
    value,
    field: { show_paper = true, ...field },
    onChange,
    ...rest
  } = props;
  const [length, setLength] = useState<number>(0);
  const toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    [{ color: [] }], // dropdown with defaults from theme
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }], // remove formatting button
    ['clean'] // remove formatting button
  ];
  const input = () => (
    <ReactQuill
      value={value}
      modules={{
        toolbar: toolbarOptions
      }}
      style={{ minHeight: 170 }}
      className={clsx(className)}
      onChange={(
          content: string,
          delta: any,
          source: Quill.Sources,
          editor: any
      ) => {
        if (field.maxChar) {
          if (
            !(editor.getLength() > field.maxChar) ||
            editor.getLength() < length
          ) {
            onChange(content);
            setLength(editor.getLength());
          } else {
            onChange(value);
          }
        } else {
          onChange(value);
        }
      }}
      {...rest}
    />
  );

  return (
    <div>
      <div
        style={
          show_paper
            ? {}
            : { border: '1px solid #E0E0E0', padding: 0, overflow: 'visible' }
        }>
        <div style={show_paper ? {} : { padding: 0 }}>
          {field.label}
          {show_paper ? <div>{input()}</div> : input()}
        </div>
      </div>
      <div>
        {field.maxChar ? field.maxChar + ' caractères espaces compris' : ''}
      </div>
    </div>
  );
}

// @ts-ignore
export default QuillEditor;
