import { Action } from "redux";
import { number } from "yup/lib/locale";


export const  SUMMARY_REQUEST_MONTH_LOADING = "SUMMARY_REQUEST_MONTH_LOADING"
export const  SUMMARY_REQUEST_MONTH_FAIL = "SUMMARY_REQUEST_MONTH_FAIL"
export const  SUMMARY_REQUEST_MONTH_SUCCESS = "SUMMARY_REQUEST_MONTH_SUCCESS"

export interface SummaryRequestMonthState {
 data:SummaryRequestMonthType[]
  loading: boolean;
}


export interface  SummaryRequestMonthType{
  data: number[]
} 




export interface SummaryRequestMonthLoading  extends Action {
  type:typeof SUMMARY_REQUEST_MONTH_LOADING
}

export interface SummaryRequestMonthFail  extends Action {
  type:typeof SUMMARY_REQUEST_MONTH_FAIL
}
export interface SummaryRequestMonthSuccess  extends Action {
  type:typeof SUMMARY_REQUEST_MONTH_SUCCESS,
  loading: boolean;
  payload:SummaryRequestMonthType
}






