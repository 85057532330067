import {
  ADD_USER_ACTION,
  ApplicationAction,
  DELETE_USER_ACTION,
  FETCH_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  User,
  UsersState,
  GET_RECENT_USER
} from "store/types";
import produce from "immer";

const initialState: UsersState = {
  range: {
    per_page: 18,
    pages: 1,
    page: 1,
    keyword: "",
  },
  data: [],
  recent_user: []
};

const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return produce(state, (draft) => {
        draft.data = action.users;
        draft.range.pages = action.pages;
        draft.range.page = action.page;
        draft.range.total = action.total;
      });
    case ADD_USER_ACTION:
      return produce(state, (draft) => {
        const users: User[] = [...state.data];
        users.push(action.user);
        draft.data = users;
      });
    case UPDATE_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.data = [...state.data].map((user) => {
          if (user.public_id == action.user.public_id) {
            return action.user;
          } else {
            return user;
          }
        });
      });
    case DELETE_USER_ACTION:
      return produce(state, (draft) => {
        let users: User[] = [...state.data];
        users = users.filter((user) => {
          return user.public_id != action.public_id;
        });
        draft.data = users;
      });
    case GET_RECENT_USER:
      return produce(state, (draft) => {
        draft.recent_user = action.data;
      });
    default: {
      return state;
    }
  }
};

export default reducer;
