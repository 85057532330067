import React from 'react';
import clsx from 'clsx';


export interface ButtonPrimaryProps  {
  children: string;
  loading?: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: any;
  variant?: string;
  color?: string;
  size?: string;
  type?: 'submit' | 'button' | 'reset';
}

export default function ButtonPrimary(props: ButtonPrimaryProps) {
  const { loading = false, disabled = false } = props;

  return (
    <button
      type={props.type}
      disabled={disabled || loading}
      onClick={props.onClick}
      className={clsx("w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ", props.className)}
    >
      {props.children}
      {loading && (
        <div>...</div>    
      )}
    </button>
  );
}
