import {
  ADD_SERVICES_REQUEST,
  ApplicationAction,
  DELETE_SERVICES_REQUEST,
  FETCH_SERVICES_ERROR,
  FETCH_SERVICES_REQUEST,
  FETCH_SERVICES_SUCCESS,
  Service,
  ServicesState,
  UPDATE_SERVICES_REQUEST,
} from "store/types";
import produce from "immer";
import { Services } from "i18next";

const initialState: ServicesState = {
  data: [],
  loading: false,
  range: {
    keyword: "",
    current_page: 1,
    pages: 1,
    per_page: 20,
    total: 0,
  },
};

const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {
    case FETCH_SERVICES_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case FETCH_SERVICES_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.data = action.services;
      });
    case FETCH_SERVICES_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
      });
    case ADD_SERVICES_REQUEST:
      var service: Service[] = [...state.data];
      service.push(action.service);
      console.log("dans le reducer action users: ", service.length);
      console.log("dans le reducer action state: ", state.data.length);
      return produce(state, (draft) => {
        draft.data = service;
      });
    case UPDATE_SERVICES_REQUEST:
      var service: Service[] = [...state.data];
      service = service.map((service) => {
        if (service.id == action.service.id) {
          return action.service;
        } else {
          return service;
        }
      });
      return produce(state, (draft) => {
        draft.data = service;
      });
    case DELETE_SERVICES_REQUEST:
      var service: Service[] = [...state.data];
      service = service.filter((service) => {
        if (service.id == action.service.id) {
          return false;
        } else {
          return true;
        }
      });
      return produce(state, (draft) => {
        draft.data = service;
      });

    default:
      return state;
  }
};

export default reducer;
