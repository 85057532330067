import React from "react";
import { Provider } from "react-redux";
// import rtl from 'jss-rtl';
import store from "store";
import Routes from "routes";
import "./index.css";

import "translations";
// import { ScrollReset } from 'components';
import Auth from "routes/Auth";
import config from "./config";
import MockedButton from "./components/MockedButton";
import moment from "moment";
// import 'styles/index.scss';

const App = () => {
  moment.locale("de");
  return (
    <Provider store={store}>
      <React.Fragment>
        <Auth>
          {/*<ScrollReset />*/}
          <Routes />
          {/* {config.env === "DEV" && <MockedButton />} */}
        </Auth>
      </React.Fragment>
    </Provider>
  );
};

export default App;
